import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { ColorState } from "@/types/DTO/color";

const initialState: ColorState = {
  colors: null,
  metadata: null,
  loading: false,
};

export const getColors = createAsyncThunk(
  "catalog/color/get",
  async (
    { query = "page=1" }: { query?: string; payload?: any } = {},
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await api.getColors({ query });
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const deleteColor = createAsyncThunk(
  "catalog/color/delete",
  async (
    { id }: { id: number },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.deleteColor(id);
      const data: any = await response.data; // Define the type for data

      dispatch(updateStore({ id }));
      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const colorSlice = createSlice({
  name: "catalog/color",
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ id: number }>) => {
      if (state.colors) {
        state.colors = state.colors.filter(
          (item) => action.payload.id !== item.id
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getColors.fulfilled, (state, action) => {
        const { colors, metadata } = action.payload;
        state.colors = colors;
        state.metadata = metadata;
        state.loading = false;
      })
      .addCase(getColors.rejected, (state, action) => {
        state.colors = null;
        state.metadata = null;
        state.loading = false;
      })
      .addCase(getColors.pending, (state, action) => {
        state.colors = null;
        state.metadata = null;
        state.loading = true;
      })
      .addCase(deleteColor.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteColor.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteColor.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { updateStore } = colorSlice.actions;
export default colorSlice.reducer;
