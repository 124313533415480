import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getSubscriptionPlans  = createAsyncThunk(
  'catalog/subscription-plan/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getSubscriptionPlans({query});
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deleteSubscriptionPlan = createAsyncThunk(
  'catalog/subscription-plan/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deleteSubscriptionPlan(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  subscriptionPlans: null,
  metadata: null,
  loading: false,
}

export const subscriptionPlanSlice = createSlice({
  name: 'catalog/subscription-plan',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.subscriptionPlans = state.subscriptionPlans.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getSubscriptionPlans.fulfilled, (state, action) => {
        const { subPlan: subscriptionPlans, metadata } = action.payload;
        state.subscriptionPlans = subscriptionPlans;
        state.metadata = metadata;
        state.loading = false;
    }),
    builder.addCase(getSubscriptionPlans.rejected, (state, action) => {
      state.subscriptionPlans = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getSubscriptionPlans.pending, (state, action) => {
      state.subscriptionPlans = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deleteSubscriptionPlan.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deleteSubscriptionPlan.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deleteSubscriptionPlan.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = subscriptionPlanSlice.actions;
export default subscriptionPlanSlice.reducer
