import { configureStore } from "@reduxjs/toolkit";
import authReducerFeature from "../features/auth/authSlice";

import { combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  newOrderTableReducer,
  sidebarReducer,
  // authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  pokemonReducer,
  cryptoTrendsReducer,
  globalQuotesReducer,
  quotesBySymbolReducer,
  topTenReducer,
} from "@/redux/reducers/index";
import covidReducer from "../containers/Maps/VectorMapWithRequestData/redux/covidReducer";
import todoReducer from "../containers/Todo/redux/reducer";
import authSlice from "../features/auth/authSlice";
// import industrySlice from "../features/catalog/industrySlice";
import categorySlice from "../features/catalog/categorySlice";
import industryProductSlice from "../features/catalog/industryProductSlice";
// import brandModelSlice from "../features/catalog/brandModelSlice";
import updatedBrandModelSlice from "@/features/catalog/updatedBrandModelSlice";
import updatedBrandSlice from "@/features/catalog/updatedBrandSlice";
// import brandSlice from "../features/catalog/brandSlice";
import issueSlice from "../features/catalog/issueSlice";
import updatedColorSlice from "@/features/catalog/updatedColorSlice";
// import colorSlice from "../features/catalog/colorSlice";
import partBrandSlice from "../features/catalog/partBrandSlice";
import partProductSlice from "../features/catalog/partProductSlice";
import repairPartSlice from "../features/catalog/repairPartSlice";
import promoCodeSlice from "../features/catalog/promoCodeSlice";
import orderSlice from "../features/common/orderSlice";
import updatedStoreSlice from "../features/common/updatedStoreSlice";
// import userSlice from "../features/common/userSlice";
import serviceSlice from "../features/common/serviceSlice";
import subscriptionPlanSlice from "../features/catalog/subscriptionPlanSlice";
import wholesaleSlice from "../features/common/wholesaleSlice";
import locationSlice from "@/features/common/locationSlice";
import storeSlice from "@/features/common/storeSlice";
import updatedIndustrySlice from "@/features/catalog/updatedIndustrySlice";
import updatedUserSlice from "@/features/common/updatedUserSlice";
import { brandModelAPI } from "./service/brandModelApi";
import { seoContentAPI } from "./service/seoContentApi";
import { industryProductAPI } from "./service/industryProductsApi";
import { locationProductSeoContentAPI } from "./service/locationSeoContentApi";
import { blogApi } from "./service/blogApi";
import { slugSearchApi } from "./service/slugSearch";
import { authorApi } from "./service/authorApi";

const reducers = combineReducers({
  [brandModelAPI.reducerPath]: brandModelAPI.reducer,
  [seoContentAPI.reducerPath]: seoContentAPI.reducer,
  [industryProductAPI.reducerPath]: industryProductAPI.reducer,
  [locationProductSeoContentAPI.reducerPath]:
    locationProductSeoContentAPI.reducer,
  [blogApi.reducerPath]: blogApi.reducer,
  [slugSearchApi.reducerPath]: slugSearchApi.reducer,
  [authorApi.reducerPath]: authorApi.reducer,
  theme: themeReducer,
  rtl: rtlReducer,
  border: roundBordersReducer,
  shadow: blocksShadowsReducer,
  customizer: customizerReducer,
  newOrder: newOrderTableReducer,
  sidebar: sidebarReducer,
  auth: authSlice,
  industry: updatedIndustrySlice,
  category: categorySlice,
  industryProduct: industryProductSlice,
  brand: updatedBrandSlice,
  brandModel: updatedBrandModelSlice,
  color: updatedColorSlice,
  issue: issueSlice,
  partBrand: partBrandSlice,
  partProduct: partProductSlice,
  repairPart: repairPartSlice,
  promoCode: promoCodeSlice,
  subscriptionPlan: subscriptionPlanSlice,
  order: orderSlice,
  store: updatedStoreSlice,
  service: serviceSlice,
  user: updatedUserSlice,
  wholesale: wholesaleSlice,
  location: locationSlice,
  covid: covidReducer,
  todo: todoReducer,
  pokemon: pokemonReducer,
  cryptoTrends: cryptoTrendsReducer,
  globalQuotes: globalQuotesReducer,
  quotesBySymbol: quotesBySymbolReducer,
  topTen: topTenReducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      brandModelAPI.middleware,
      seoContentAPI.middleware,
      industryProductAPI.middleware,
      locationProductSeoContentAPI.middleware,
      blogApi.middleware,
      slugSearchApi.middleware,
      authorApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
