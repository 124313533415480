import {
  IAuthorList,
  IAuthorResponse,
  IAuthor,
  ICreateAuthor,
  IUpdateAuthor,
} from "@/types/DTO/author";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const localhostList = ["localhost", "127.0.0.1"];

export const authorApi = createApi({
  reducerPath: "authorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: localhostList.includes(window.location.hostname)
      ? import.meta.env.VITE_REACT_APP_LOCAL_URL
      : import.meta.env.VITE_REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Author"],
  endpoints: (builder) => ({
    getAll: builder.query<IAuthorList, { page: number }>({
      query: ({ page }) => ({
        url: `author?limit=10&page=${page}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.authors.map(({ id }) => ({
                type: "Author" as const,
                id,
              })),
              { type: "Author", id: "LIST" },
            ]
          : [{ type: "Author", id: "LIST" }],
    }),
    getOne: builder.query<IAuthorResponse, { id: string }>({
      query: ({ id }) => ({
        url: "/author/" + id,
        method: "GET",
      }),
      providesTags: (result, error, args) =>
        result
          ? [{ type: "Author" as const, id: args.id }]
          : [{ type: "Author" as const, id: "LIST" }],
    }),
    createAuthor: builder.mutation<IAuthorResponse, ICreateAuthor>({
      query: (body) => ({
        url: "admin/author",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Author"],
    }),
    uploadAvatar: builder.mutation({
      query: ({ id, ...body }) => {
        const data = new FormData();
        data.append("fileKey", body.fileKey);
        data.append("fileContent", body.fileContent);
        return {
          url: `/admin/author/${id}/image/upload`,
          method: "PATCH",
          body: data,
          formData: true,
        };
      },
    }),
    updateAuthor: builder.mutation<IAuthorResponse, IUpdateAuthor>({
      query: ({ id, ...body }) => ({
        url: `admin/author/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Author" as const, id: args.id },
      ],
    }),
    getAllAuthorsByKeyword: builder.query<IAuthorList, { value: string }>({
      query: ({ value }) => ({
        url: `author?searchText=${value}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.authors.map(({ id }) => ({
                type: "Author" as const,
                id,
              })),
              { type: "Author", id: "LIST" },
            ]
          : [{ type: "Author", id: "LIST" }],
    }),
    deleteAuthor: builder.mutation({
      query: (id) => ({
        url: "admin/author/" + id,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Author" as const, id: args.id },
      ],
    }),
  }),
});

export const {
  useGetAllQuery,
  useLazyGetOneQuery,
  useCreateAuthorMutation,
  useUploadAvatarMutation,
  useUpdateAuthorMutation,
  useDeleteAuthorMutation,
  useLazyGetAllAuthorsByKeywordQuery,
} = authorApi;
