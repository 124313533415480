import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getCategories = createAsyncThunk(
  'catalog/category/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getCategories({query});
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'catalog/category/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deleteCategory(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  categories: null,
  metadata: null,
  loading: false,
}

export const categorySlice = createSlice({
  name: 'catalog/category',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.categories = state.categories.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
        const { categories, metadata } = action.payload;
        state.categories = categories;
        state.metadata = metadata;
        state.loading = false;
    }),
    builder.addCase(getCategories.rejected, (state, action) => {
      state.categories = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getCategories.pending, (state, action) => {
      state.categories = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deleteCategory.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = categorySlice.actions;
export default categorySlice.reducer
