//   import { INewLocationSeoContet, IUpdateLocationSeoContent } from "@/types/Forms/seo-content";
import {
  ILocationProductSeoContent,
  ILocationProductSeoContentListResponse,
  ILocationProductSeoContentResponse,
} from "@/types/DTO/location-product-seo-content";
import {
  INewLocationProductSeoContent,
  IUpdateLocationProductSeoContent,
} from "@/types/Forms/location-product-seo-content";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const localhostList = ["localhost", "127.0.0.1"];

export const locationProductSeoContentAPI = createApi({
  reducerPath: "locationProductSeoContentAPi",
  baseQuery: fetchBaseQuery({
    baseUrl: localhostList.includes(window.location.hostname)
      ? import.meta.env.VITE_REACT_APP_LOCAL_URL
      : import.meta.env.VITE_REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["LocationProductSeoContent"],
  endpoints: (builder) => ({
    getAll: builder.query<
      ILocationProductSeoContentListResponse,
      { page: number }
    >({
      query: ({ page = 1 }) => ({
        url: `admin/location-product-seo-content?limit=10&page=${page}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.seoContents.map(({ id }) => ({
                type: "LocationProductSeoContent" as const,
                id,
              })),
              { type: "LocationProductSeoContent", id: "LIST" },
            ]
          : [{ type: "LocationProductSeoContent", id: "LIST" }],
    }),
    getOne: builder.query<ILocationProductSeoContentResponse, { id: string }>({
      query: ({ id }) => ({
        url: `admin/location-product-seo-content/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, args) =>
        result
          ? [{ type: "LocationProductSeoContent" as const, id: args.id }]
          : [{ type: "LocationProductSeoContent" as const, id: "LIST" }],
    }),
    createLocationProductSeoContent: builder.mutation<
      { seoContent: ILocationProductSeoContent; success: number },
      INewLocationProductSeoContent
    >({
      query: (body) => ({
        url: "admin/location-product-seo-content",
        method: "POST",
        body,
      }),
      invalidatesTags: ["LocationProductSeoContent"],
    }),
    updateLocationProductSeoContent: builder.mutation<
      { seoContent: ILocationProductSeoContent; success: number },
      IUpdateLocationProductSeoContent
    >({
      query: ({ id, ...body }) => ({
        url: "admin/location-product-seo-content/" + id,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, args) => [
        { type: "LocationProductSeoContent" as const, id: args.id },
      ],
    }),
    deleteLocationProductSeoContent: builder.mutation({
      query: (id) => ({
        url: "admin/location-product-seo-content/" + id,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, args) => [
        { type: "LocationProductSeoContent" as const, id: args.id },
      ],
    }),
  }),
});

export const {
  useGetAllQuery,
  useLazyGetOneQuery,
  useCreateLocationProductSeoContentMutation,
  useUpdateLocationProductSeoContentMutation,
  useDeleteLocationProductSeoContentMutation,
} = locationProductSeoContentAPI;
