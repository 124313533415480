import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getPromoCodes = createAsyncThunk(
  'catalog/promo-code/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getPromoCodes({query});
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deletePromoCode= createAsyncThunk(
  'catalog/promo-code/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deletePromoCode(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  promoCodes: null,
  metadata: null,
  loading: false,
}

export const promoCodeSlice = createSlice({
  name: 'catalog/promo-code',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.promoCodes = state.promoCodes.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getPromoCodes.fulfilled, (state, action) => {
      const { promoCodes, metadata } = action.payload;
      state.promoCodes = promoCodes;
      state.metadata = metadata;
      state.loading = false;
    }),
    builder.addCase(getPromoCodes.rejected, (state, action) => {
      state.promoCodes = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getPromoCodes.pending, (state, action) => {
      state.promoCodes = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deletePromoCode.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deletePromoCode.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deletePromoCode.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = promoCodeSlice.actions;
export default promoCodeSlice.reducer
