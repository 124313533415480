import {
  ISeoContent,
  ISeoContentListResponse,
  ISeoContentResponse,
} from "@/types/DTO/seo-content";
import { INewSeoContet, IUpdateSeoContent } from "@/types/Forms/seo-content";
import { buildSelectors } from "@reduxjs/toolkit/dist/query/core/buildSelectors";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const localhostList = ["localhost", "127.0.0.1"];

export const seoContentAPI = createApi({
  reducerPath: "seoContentAPi",
  baseQuery: fetchBaseQuery({
    baseUrl: localhostList.includes(window.location.hostname)
      ? import.meta.env.VITE_REACT_APP_LOCAL_URL
      : import.meta.env.VITE_REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["SeoContent"],
  endpoints: (builder) => ({
    getAll: builder.query<ISeoContentListResponse, { page: number }>({
      query: ({ page = 1 }) => ({
        url: `admin/seo-content?sortBy=id&limit=10&page=${page}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.seoContents.map(({ id }) => ({
                type: "SeoContent" as const,
                id,
              })),
              { type: "SeoContent", id: "LIST" },
            ]
          : [{ type: "SeoContent", id: "LIST" }],
    }),
    getOne: builder.query<ISeoContentResponse, { id: string }>({
      query: ({ id }) => ({
        url: `admin/seo-content/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, args) =>
        result
          ? [{ type: "SeoContent" as const, id: args.id }]
          : [{ type: "SeoContent" as const, id: "LIST" }],
    }),
    createSeoContent: builder.mutation<
      { seoContent: ISeoContent; success: number },
      INewSeoContet
    >({
      query: (body) => ({
        url: "admin/seo-content",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SeoContent"],
    }),
    updateSeoContent: builder.mutation<
      { seoContent: ISeoContent; success: number },
      IUpdateSeoContent
    >({
      query: ({ id, ...body }) => ({
        url: "admin/seo-content/" + id,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, args) => [
        { type: "SeoContent" as const, id: args.id },
      ],
    }),
    deleteSeoContent: builder.mutation({
      query: (id) => ({
        url: "admin/seo-content/" + id,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, args) => [
        { type: "SeoContent" as const, id: args.id },
      ],
    }),
  }),
});

export const {
  useGetAllQuery,
  useLazyGetOneQuery,
  useCreateSeoContentMutation,
  useDeleteSeoContentMutation,
  useUpdateSeoContentMutation
} = seoContentAPI;
