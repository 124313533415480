import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getPartProducts  = createAsyncThunk(
  'catalog/part-product/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getPartProducts({query});
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deletePartProduct = createAsyncThunk(
  'catalog/part-product/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deletePartProduct(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  partProducts: null,
  metadata: null,
  loading: false,
}

export const partProductSlice = createSlice({
  name: 'catalog/part-product',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.partProducts = state.partProducts.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getPartProducts.fulfilled, (state, action) => {
        const { partProducts, metadata } = action.payload;
        state.partProducts = partProducts;
        state.metadata = metadata;
        state.loading = false;
    }),
    builder.addCase(getPartProducts.rejected, (state, action) => {
      state.partProducts = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getPartProducts.pending, (state, action) => {
      state.partProducts = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deletePartProduct.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deletePartProduct.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deletePartProduct.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = partProductSlice.actions;
export default partProductSlice.reducer
