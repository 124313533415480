import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getWholesales = createAsyncThunk(
  'catalog/wholesales/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getWholesales({query});
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deleteWholesale = createAsyncThunk(
  'catalog/wholesales/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deleteWholesale(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  wholesales: null,
  metadata: null,
  loading: false,
}

export const wholesaleSlice = createSlice({
  name: 'catalog/wholesales',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.wholesales = state.wholesales.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getWholesales.fulfilled, (state, action) => {
        const { wholesales, metadata } = action.payload;
        state.wholesales = wholesales;
        state.metadata = metadata;
        state.loading = false;
    }),
    builder.addCase(getWholesales.rejected, (state, action) => {
      state.wholesales = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getWholesales.pending, (state, action) => {
      state.wholesales = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deleteWholesale.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deleteWholesale.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deleteWholesale.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = wholesaleSlice.actions;
export default wholesaleSlice.reducer
