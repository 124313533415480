import React from 'react';
import { useForm } from "react-hook-form";
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import PasswordField from '@/shared/components/form/Password';
import FormField from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import renderCheckBoxField from '../../form/CheckBox';
import {
 AccountButton, AccountButtons, AccountForgotPassword, LoginForm, 
} from '../AccountElements';
import validate from './validate';

const LogInForm = ({
  onSubmit, errorMessage, fieldUser, typeFieldUser, form,
}) => {
  return (
    <Form onSubmit={onSubmit} validate={validate} >
      {({ handleSubmit }) => (
        <LoginForm onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>{fieldUser}</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="email"
                // initialValue="admin@gmail.com"
                type="email"
                component={FormField}
                placeholder="example@mail.com"
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="password"
                // initialValue="perviz123"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                keyIcon
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                name="remember_me"
                render={renderCheckBoxField}
                label="Remember me"
                type="checkbox"
              />
            </FormGroupField>
          </FormGroup>
          <AccountButtons>
            <AccountButton type="submit" variant="primary">Sign In</AccountButton>
          </AccountButtons>
        </LoginForm>
      )}
    </Form>
  );
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

export default LogInForm;
