import React, { useLayoutEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LogIn from '../../Account/LogIn/index';

import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../../features/auth/authSlice';
import { Suspense } from 'react';
import { lazy } from 'react';
import Loading from '@/shared/components/Loading';
import { RootState } from '@/redux/store';

import { adminInstance, globalInstance, uploadInstance } from '../../../utils/api/base/axios';

const WrappedRoutes = lazy(() => import('./WrappedRoutes'));

const Router: React.FC = () => {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (!user && storedUser) {
      dispatch(setUser(JSON.parse(storedUser)));
    }

    if (!storedUser) {
      history.push('/login');
    } else {
      adminInstance.defaults.withCredentials = true;
      globalInstance.defaults.withCredentials = true;
      uploadInstance.defaults.withCredentials = true;

      if (history?.location?.pathname === '/login') {
        history.push('/');
      }
    }
  }, [user, history, dispatch]);

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Suspense fallback={<Loading loading={true} />}>
            {user ? (
              <>
                <Route path="/" component={WrappedRoutes} />
              </>
            ) : (
              <>
                <Route exact path="/login" component={LogIn} />
              </>
            )}
            <Route path="/404" component={NotFound404} />
          </Suspense>
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
