import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { BrandState } from '@/types/DTO/brand';

const initialState: BrandState = {
  brands: null,
  metadata: null,
  loading: false,
};

export const getBrands = createAsyncThunk(
  'catalog/brand/get',
  async ({ query = 'page=1' }: { query?: string; } = {}, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.getBrands({ query });
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const deleteBrand = createAsyncThunk(
  'catalog/brand/delete',
  async ({ id }: { id: number }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.deleteBrand(id);
      const data: any = await response.data; // Define the type for data

      dispatch(updateStore({ id }));
      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const brandSlice = createSlice({
  name: 'catalog/brand',
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ id: number }>) => {
      if (state.brands) {
        state.brands = state.brands.filter((item) => action.payload.id !== item.id);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.fulfilled, (state, action) => {
        const { brands, metadata } = action.payload;
        state.brands = brands;
        state.metadata = metadata;
        state.loading = false;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.brands = null;
        state.metadata = null;
        state.loading = false;
      })
      .addCase(getBrands.pending, (state, action) => {
        state.brands = null;
        state.metadata = null;
        state.loading = true;
      })
      .addCase(deleteBrand.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBrand.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteBrand.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { updateStore } = brandSlice.actions;
export default brandSlice.reducer;
