import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { showNotification } from '../../shared/components/Notification';

interface UserData {
  userName: string;
  id: string;
  email: string;
  role: string;
  avatar: string;
}

export const signIn = createAsyncThunk(
  'auth/signin',
  async (
    { data: payload, setCookie }: { data: any; setCookie: (name: string, value: UserData, options: any) => void },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await api.signIn(payload);
      const data = await response.data;

      localStorage.setItem('user', JSON.stringify(data.user));

      let { id, firstName, email, role, image } = data.user;
      let userData: UserData = {
        userName: firstName,
        id,
        email,
        role,
        avatar: image,
      };

      import.meta.env.PROD 
        ? setCookie("userData", userData, { path: "/", secure: true, domain: '.simplyfixable.com' }) 
        : setCookie("userData", userData, { path: "/", secure: true })

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async ({ removeCookie }: { removeCookie: (name: string) => void }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data: { success, message } } = await api.logout();

      if (success) {
        removeCookie('userData');
        localStorage.removeItem('user');
        showNotification('dark', 'ltr', 'Logout Action', 'User logout successfully! Thanks :)', 'success');
      }
    } catch (error) {
      console.log({ error });
    }
  }
);


const initialState = {
  user: null,
  accessToken: null,
  loading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      const { user, access_token: accessToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.loading = false;
    }),
      builder.addCase(signIn.rejected, (state, action) => {
        state.user = null;
        state.accessToken = null;
        state.loading = false;
      }),
      builder.addCase(signIn.pending, (state, action) => {
        state.user = null;
        state.accessToken = null;
        state.loading = true;
      }),
      builder.addCase(logout.fulfilled, (state, action) => {
        state.user = null;
        state.accessToken = null;
        state.loading = false;
      })
  }
});

export const { setAccessToken, setUser } = authSlice.actions;
export default authSlice.reducer
