import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { IStore } from '@/types/DTO/store';
import { IMetadata } from '@/types/DTO/metadata';


interface StoreState {
  stores: IStore[] | null;
  metadata: IMetadata | null; // Update this to the actual metadata type if available
  loading: boolean;
}

export const getStores = createAsyncThunk(
  'catalog/store/get',
  async ({ query = 'page=1' }: { query?: string } = {}, { fulfillWithValue, rejectWithValue }) => {
    try {
      console.log("fetch ")
      const response = await api.getStores({ query });
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const deleteStore = createAsyncThunk(
  'catalog/store/delete',
  async ({ id }: { id: string | number }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.deleteStore(id);
      const data = await response.data;

      dispatch(updateStore({ id }));

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const initialState: StoreState = {
  stores: null,
  metadata: null,
  loading: false,
};

export const storeSlice = createSlice({
  name: 'catalog/store',
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ id: string | number }>) => {
      state.stores = state.stores?.filter(item => action.payload.id !== item.id) || null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStores.fulfilled, (state, action) => {
      const { stores, metadata } = action.payload;
      state.stores = stores;
      state.metadata = metadata;
      state.loading = false;
    });
    builder.addCase(getStores.rejected, (state, action) => {
      state.stores = null;
      state.metadata = null;
      state.loading = false;
    });
    builder.addCase(getStores.pending, (state, action) => {
      state.stores = null;
      state.metadata = null;
      state.loading = true;
    });
    builder.addCase(deleteStore.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteStore.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteStore.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const { updateStore } = storeSlice.actions;
export default storeSlice.reducer;
