import { IMetadata } from "@/types/DTO/metadata";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const localhostList = ["localhost", "127.0.0.1"];

export type ISearchTextList = {
  success: number;
  products: ISlugText[];
  metadata: IMetadata;
};

export type ISlugText = {
  id?: number;
  slug: string;
};

export const slugSearchApi = createApi({
  reducerPath: "slugSearchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: localhostList.includes(window.location.hostname)
      ? import.meta.env.VITE_REACT_APP_LOCAL_URL
      : import.meta.env.VITE_REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["SlugSearch"],
  endpoints: (builder) => ({
    getAllSlugs: builder.query<ISearchTextList, { value?: string }>({
      query: ({ value }) => ({
        url: `admin/industry-product/slugs?searchText=${value}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.products.map(({ slug }) => ({
                type: "SlugSearch" as const,
                slug,
              })),
              { type: "SlugSearch", id: "LIST" },
            ]
          : [{ type: "SlugSearch", id: "LIST" }],
    }),
  }),
});

export const { useLazyGetAllSlugsQuery } = slugSearchApi;
