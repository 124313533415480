import { IIndustryProductListResponse } from "@/types/DTO/industryProduct";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const industryProductAPI = createApi({
  reducerPath: "industryProductAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_REACT_APP_API_URL,
    // localhostList.includes(window.location.hostname)
    // ? import.meta.env.VITE_REACT_APP_LOCAL_URL
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["IndustryProduct"],
  endpoints: (builder) => ({
    getAll: builder.query<IIndustryProductListResponse, { page: number }>({
      query: ({ page = 1 }) => ({
        url: `industry-product?limit=10&page=${page}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.products.map(({ id }) => ({
                type: "IndustryProduct" as const,
                id,
              })),
              { type: "IndustryProduct", id: "LIST" },
            ]
          : [{ type: "IndustryProduct", id: "LIST" }],
    }),
  }),
});

export const { useGetAllQuery } = industryProductAPI;
