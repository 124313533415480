// Admin Dashboard api urls
import { adminInstance, globalInstance, uploadInstance } from "./base/axios";

export const adminUrls = {
    // auth
    signIn: '/auth/signin',
    logout: '/auth/logout',
    verifyJWT: '/auth/password/change',
    updatePassword: '/auth/password/update',
    verifyUser: '/auth/verify_user',
    user: '/user',
    // catalog
    industry: '/industry',
    category: '/category',
    subCategory: '/category/sub',
    industryProduct: '/industry-product',
    brand: '/brand',
    brandModel: '/brand-model',
    color: '/color',
    colorModel: '/color/models',
    issue: '/issue',
    subscriptionPlan: '/subscription-plan',
    promoCode: '/promo-code',
    partBrand: '/part-brand',
    partProduct: '/part-product',
    repairPart: '/repair-part',
    order: '/order',
    store: '/store',
    storeWorkHour: '/store-work-hour',
    service: '/service',
    wholesale: '/wholesale',
    location: '/location',
}

export const globalUrls = {
    industry: '/industry',
    category: '/category',
    subCategory: '/category/sub',
    industryProduct: '/industry-product',
    brand: '/brand',
    brandModel: '/brand-model',
    color: '/color',
    issue: '/issue',
    subscriptionPlan: '/subscription-plan',
    promoCode: '/promo-code',
    partBrand: '/part-brand',
    partProduct: '/part-product',
    repairPart: '/repair-part',
    order: '/order',
    store: '/store',
    storeWorkHours: '/store/workHours',
    service: '/service',
    wholesale: '/wholesale',
    location: '/location',
}

export class Api {
    // admin auth
    signIn  = (data) => adminInstance.post(adminUrls.signIn, data);
    logout  = ()     => adminInstance.get(adminUrls.logout);

    // uploadIndusctryProductImage = (data) => uploadInstance.post(`admin${adminUrls.industryProduct}/image/upload`, data)

    // Upload Files
    uploadUserAvatar        = (id, data) => uploadInstance.post(`${globalUrls.user}/${id}/avatar/upload`, data)
    uploadLogoStore         = (id, data) => uploadInstance.post(`${adminUrls.store}/${id}/logo/upload`, data)
    uploadIndustryProduct   = (id, data) => uploadInstance.post(`${adminUrls.industryProduct}/${id}/image/upload`, data)
    uploadWholesale         = (id, data) => uploadInstance.post(`${adminUrls.wholesale}/${id}/image/upload`, data)
    uploadBrand             = (id, data) => uploadInstance.post(`${adminUrls.brand}/${id}/image/upload`, data)

    uploadBrandModel        = (data) => uploadInstance.post(`${adminUrls.brandModel}/image/upload`, data)
    
    uploadIssue             = (id, data) => uploadInstance.post(`${adminUrls.issue}/${id}/image/upload`, data)
    uploadPromoCode         = (id, data) => uploadInstance.post(`${adminUrls.promoCode}/${id}/image/upload`, data)
    uploadColor             = (id, data) => uploadInstance.post(`${adminUrls.color}/${id}/image/upload`, data)
    uploadPartBrand         = (id, data) => uploadInstance.post(`${adminUrls.partBrand}/${id}/image/upload`, data)
    uploadPartProduct       = (id, data) => uploadInstance.post(`${adminUrls.partProduct}/${id}/image/upload`, data)
    uploadRepairPart        = (id, data) => uploadInstance.post(`${adminUrls.repairPart}/${id}/image/upload`, data)

    // Admin Models
    createIndustry          = (data)     => adminInstance.post(adminUrls.industry, data)
    editIndustry            = (id, data) => adminInstance.put(`${adminUrls.industry}/${id}`, data)
    deleteIndustry          = (id)       => adminInstance.delete(`${adminUrls.industry}/${id}`)
    createSubCategory       = (data)     => adminInstance.post(adminUrls.subCategory, data)
    createCategory          = (data)     => adminInstance.post(adminUrls.category, data)
    editCategory            = (id, data) => adminInstance.put(`${adminUrls.category}/${id}`, data)
    deleteCategory          = (id)       => adminInstance.delete(`${adminUrls.category}/${id}`)
    createIndustryProduct   = (data)     => adminInstance.post(adminUrls.industryProduct, data)
    editIndustryProduct     = (id, data) => adminInstance.put(`${adminUrls.industryProduct}/${id}`, data)
    deleteIndustryProduct   = (id)       => adminInstance.delete(`${adminUrls.industryProduct}/${id}`)
    createBrand             = (data)     => adminInstance.post(adminUrls.brand, data)
    editBrand               = (id, data) => adminInstance.put(`${adminUrls.brand}/${id}`, data)
    deleteBrand             = (id)       => adminInstance.delete(`${adminUrls.brand}/${id}`)
    createBrandModel        = (data)     => adminInstance.post(adminUrls.brandModel, data)
    editBrandModel          = (id, data) => adminInstance.put(adminUrls.brandModel + "/" + id, data)
    deleteBrandModel        = (id)       => adminInstance.delete(`${adminUrls.brandModel}/${id}`)
    createColor             = (data)     => adminInstance.post(adminUrls.color, data)
    createColorModel        = (id,data)  => adminInstance.post(`${adminUrls.color}/addModel/${id}`, data)
    editColor               = (id, data) => adminInstance.put(`${adminUrls.color}/${id}`, data)
    deleteColor             = (id)       => adminInstance.delete(`${adminUrls.color}/${id}`)
    createIssue             = (data)     => adminInstance.post(adminUrls.issue, data)
    editIssue               = (id, data) => adminInstance.put(`${adminUrls.issue}/${id}`, data)
    deleteIssue             = (id)       => adminInstance.delete(`${adminUrls.issue}/${id}`)
    createSubscriptionPlan  = (data)     => adminInstance.post(adminUrls.subscriptionPlan, data)
    editSubscriptionPlan    = (id, data) => adminInstance.put(`${adminUrls.subscriptionPlan}/${id}`, data)
    deleteSubscriptionPlan  = (id)       => adminInstance.delete(`${adminUrls.subscriptionPlan}/${id}`)
    createPartBrand         = (data)     => adminInstance.post(adminUrls.partBrand, data)
    editPartBrand           = (id, data) => adminInstance.put(`${adminUrls.partBrand}/${id}`, data)
    deletePartBrand         = (id)       => adminInstance.delete(`${adminUrls.partBrand}/${id}`)
    createPartProduct       = (data)     => adminInstance.post(adminUrls.partProduct, data)
    editPartProduct         = (id, data) => adminInstance.put(`${adminUrls.partProduct}/${id}`, data)
    deletePartProduct       = (id)       => adminInstance.delete(`${adminUrls.partProduct}/${id}`)
    createRepairPart        = (data)     => adminInstance.post(adminUrls.repairPart, data)
    editRepairPart          = (id, data) => adminInstance.put(`${adminUrls.repairPart}/${id}`, data)
    deleteRepairPart        = (id)       => adminInstance.delete(`${adminUrls.repairPart}/${id}`)
    createPromoCode         = (data)     => adminInstance.post(adminUrls.promoCode, data)
    editPromoCode           = (id, data) => adminInstance.put(`${adminUrls.promoCode}/${id}`, data)
    deletePromoCode         = (id)       => adminInstance.delete(`${adminUrls.promoCode}/${id}`)

    createOrder             = (data)     => adminInstance.post(adminUrls.order, data)
    editOrder               = (id, data) => adminInstance.put(`${adminUrls.order}/${id}`, data)
    deleteOrder             = (id)       => adminInstance.delete(`${adminUrls.order}/${id}`)
    createStore             = (data)     => adminInstance.post(adminUrls.store, data)
    editStore               = (id, data) => adminInstance.put(`${adminUrls.store}/${id}`, data)
    deleteStore             = (id)       => adminInstance.delete(`${adminUrls.store}/${id}`)
    createStoreWorkHour     = (data)     => globalInstance.post(adminUrls.storeWorkHour, data)
    editStoreWorkHour       = (id, data) => globalInstance.put(`${adminUrls.storeWorkHour}/${id}`, data)
    deleteStoreWorkHour     = (id)       => globalInstance.delete(`${adminUrls.storeWorkHour}/${id}`)
    createService           = (data)     => globalInstance.post(adminUrls.service, data)
    editService             = (id, data) => globalInstance.put(`${adminUrls.service}/${id}`, data)
    deleteService           = (id)       => globalInstance.delete(`${adminUrls.service}/${id}`)
    createWholesale         = (data)     => adminInstance.post(adminUrls.wholesale, data)
    editWholesale           = (id, data) => adminInstance.put(`${adminUrls.wholesale}/${id}`, data)
    deleteWholesale         = (id)       => adminInstance.delete(`${adminUrls.wholesale}/${id}`)
    createUser              = (data)     => adminInstance.post(adminUrls.user, data)
    editUser                = (id, data) => adminInstance.put(`${adminUrls.user}/${id}`, data)
    deleteUser              = (id)       => adminInstance.delete(`${adminUrls.user}/${id}`)
    createLocation          = (data)     => adminInstance.post(adminUrls.location, data)
    editLocation            = (id, data) => adminInstance.put(`${adminUrls.location}/${id}`, data)
    deleteLocation          = (id)       => adminInstance.delete(`${adminUrls.location}/${id}`)

    // Global Models
    getUsers                = ({query}) => adminInstance.get(`${adminUrls.user}?${query}`);
    getUser                 = (id)      => adminInstance.get(`${adminUrls.user}/${id}`);
    getOrders               = ({query}) => adminInstance.get(`${adminUrls.order}?${query}`);
    getOrder                = (id)      => adminInstance.get(`${adminUrls.order}/${id}`);
    getStores               = ({query}) => adminInstance.get(`${adminUrls.store}?${query}`);
    getStore                = (id)      => globalInstance.get(`${adminUrls.store}/${id}`);
    getStoreWorkHours       = (id)      => globalInstance.get(`${globalUrls.storeWorkHours}/${id}`);
    getServices             = ({query}) => globalInstance.get(`${adminUrls.service}?${query}`);
    getService              = (id)      => globalInstance.get(`${adminUrls.service}/${id}`);
    getWholesales           = ({query}) => globalInstance.get(`${adminUrls.wholesale}?${query}`);
    getWholesale            = (id)      => globalInstance.get(`${adminUrls.wholesale}/${id}`);
    getLocations            = ({query}) => adminInstance.get(`${adminUrls.location}?${query}`);
    getLocation             = (id)      => globalInstance.get(`${adminUrls.location}/${id}`);

    getIndustries           = ({query}) => adminInstance.get(`${globalUrls.industry}?${query}`);
    getIndustry             = (id)      => adminInstance.get(`${globalUrls.industry}/${id}`);
    
    getCategories           = ({query}) => globalInstance.get(`${globalUrls.category}?${query}`);
    getCategory             = (id)      => globalInstance.get(`${globalUrls.category}/${id}`);
    getSubCategories        = (id,{query}) => adminInstance.get(`${globalUrls.category}/${id}/sub?${query}`);
    getIndustryProducts     = ({query}) => globalInstance.get(`${globalUrls.industryProduct}?${query}`);
    getIndustryProduct      = (id)      => globalInstance.get(`${globalUrls.industryProduct}/${id}`);
    getBrands               = ({query}) => globalInstance.get(`${globalUrls.brand}?${query}`);
    getBrand                = (id)      => globalInstance.get(`${globalUrls.brand}/${id}`);
    getBrandModels          = ({query}) => globalInstance.get(`${globalUrls.brandModel}?${query}`);
    getBrandModel           = (id)      => globalInstance.get(`${globalUrls.brandModel}/${id}`);
    getColors               = ({query}) => globalInstance.get(`${globalUrls.color}?${query}`);
    getColor                = (id)      => globalInstance.get(`${globalUrls.color}/${id}`);
    getColorModels          = (id) => adminInstance.get(`${adminUrls.colorModel}?colorId=${id}`);
    getIssues               = ({query}) => globalInstance.get(`${globalUrls.issue}?${query}`);
    getIssue                = (id)      => globalInstance.get(`${globalUrls.issue}/${id}`);
    getSubscriptionPlans    = ({query}) => globalInstance.get(`${globalUrls.subscriptionPlan}?${query}`);
    getSubscriptionPlan     = (id)      => globalInstance.get(`${globalUrls.subscriptionPlan}/${id}`);
    getPartBrands           = ({query}) => globalInstance.get(`${globalUrls.partBrand}?${query}`);
    getPartBrand            = (id)      => globalInstance.get(`${globalUrls.partBrand}/${id}`);
    getPartProducts         = ({query}) => globalInstance.get(`${globalUrls.partProduct}?${query}`);
    getPartProduct          = (id)      => globalInstance.get(`${globalUrls.partProduct}/${id}`);
    getRepairParts          = ({query}) => globalInstance.get(`${globalUrls.repairPart}?${query}`);
    getRepairPart           = (id)      => globalInstance.get(`${globalUrls.repairPart}/${id}`);
    getPromoCodes           = ({query}) => adminInstance.get(`${globalUrls.promoCode}?${query}`);
    getPromoCode            = (id)      => adminInstance.get(`${globalUrls.promoCode}/${id}`);

    getData = async (url) => await globalInstance.get(url);
}

const api = new Api();

export default api;