import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getServices = createAsyncThunk(
  'catalog/service/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getServices({query});
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deleteService = createAsyncThunk(
  'catalog/service/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deleteService(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  services: null,
  metadata: null,
  loading: false,
}

export const serviceSlice = createSlice({
  name: 'catalog/service',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.services = state.services.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      const { services, metadata } = action.payload;
      state.services = services;
      state.metadata = metadata;
      state.loading = false;
    }),
    builder.addCase(getServices.rejected, (state, action) => {
      state.services = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getServices.pending, (state, action) => {
      state.services = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deleteService.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deleteService.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deleteService.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = serviceSlice.actions;
export default serviceSlice.reducer
