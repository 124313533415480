import {
  IBlog,
  IBlogListResponse,
  IBlogResponse,
  ICreateBlog,
  IUpdateBlog,
} from "@/types/DTO/blog";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const localhostList = ["localhost", "127.0.0.1"];

export const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: localhostList.includes(window.location.hostname)
      ? import.meta.env.VITE_REACT_APP_LOCAL_URL
      : import.meta.env.VITE_REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Blog"],
  endpoints: (builder) => ({
    getAll: builder.query<IBlogListResponse, { page: number }>({
      query: ({ page = 1 }) => ({
        url: `admin/blog?limit=10&page=${page}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.blogs.map(({ _id }) => ({
                type: "Blog" as const,
                _id,
              })),
              { type: "Blog", id: "LIST" },
            ]
          : [{ type: "Blog", id: "LIST" }],
    }),
    getOne: builder.query<IBlogResponse, { id: string }>({
      query: ({ id }) => ({
        url: `blog/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, args) =>
        result
          ? [{ type: "Blog" as const, id: args.id }]
          : [{ type: "Blog", id: "LIST" }],
    }),
    createBlog: builder.mutation<{ blog: IBlog; success: number }, ICreateBlog>(
      {
        query: (body) => ({
          url: "admin/blog",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Blog"],
      }
    ),
    uploadBlogImage: builder.mutation({
      query: ({ id, ...body }) => {
        const data = new FormData();
        if (id) {
          data.append("id", id);
        }
        data.append("fileKey", body.fileKey);
        data.append("fileContent", body.fileContent);
        return {
          url: `/admin/blog/upload`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "Blog" as const, id: args.id },
      ],
    }),
    updateBlog: builder.mutation<{ blog: IBlog; success: number }, IUpdateBlog>(
      {
        query: ({ id, ...body }) => ({
          url: `admin/blog/` + id,
          method: "PUT",
          body,
        }),
        invalidatesTags: (result, error, args) => [
          { type: "Blog" as const, id: args.id },
        ],
      }
    ),
    deleteBlog: builder.mutation({
      query: (id) => ({
        url: "admin/blog/" + id,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Blog" as const, id: args.id },
      ],
    }),
  }),
});

export const {
  useGetAllQuery,
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useLazyGetOneQuery,
  useUploadBlogImageMutation,
  useUpdateBlogMutation,
} = blogApi;
