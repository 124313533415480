import React from 'react';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import { setUser, signIn } from '../../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { CookiesProvider, useCookies } from "react-cookie";

const LogIn = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["userData"]);

  const handleLogin = (data) => {
    dispatch(signIn({ data, setCookie}));
  }

  return (
  <AccountWrap>
    <AccountContent>
      <AccountCard>
        <AccountHead>
          <AccountTitle>
            <AccountLogo>Simply Fixable 
              <AccountLogoAccent> Inc</AccountLogoAccent>
            </AccountLogo>
          </AccountTitle>
          <h4 className="subhead">Welcome to Admin Dashboard</h4>
        </AccountHead>
        <LogInForm onSubmit={handleLogin} form="log_in_form" />
      </AccountCard>
    </AccountContent>
  </AccountWrap>
)};

export default LogIn;
