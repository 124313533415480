import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IBrandModelListResponse,
  IBrandModelResponse,
} from "@/types/DTO/brand-models";

const localhostList = ["localhost", "127.0.0.1"];

export const brandModelAPI = createApi({
  reducerPath: "brandModelAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: localhostList.includes(window.location.hostname)
      ? import.meta.env.VITE_REACT_APP_LOCAL_URL
      : import.meta.env.VITE_REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const cookies = document.cookie;
      headers.set("Cookie", cookies);
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["BrandModel"],
  endpoints: (builder) => ({
    getAll: builder.query<IBrandModelListResponse, {}>({
      query: ({}) => ({
        url: `brand-model`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.brandModels.map(({ id }) => ({
                type: "BrandModel" as const,
                id,
              })),
              { type: "BrandModel", id: "LIST" },
            ]
          : [{ type: "BrandModel", id: "LIST" }],
    }),
    getOne: builder.query<IBrandModelResponse, { id: string }>({
      query: ({ id }) => ({
        url: `brand-model/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, args) =>
        result
          ? [{ type: "BrandModel" as const, id: args.id }]
          : [{ type: "BrandModel" as const, id: "LIST" }],
    }),
    uploadImage: builder.mutation({
      query: ({ id, ...body }) => {
        const data = new FormData();
        if (id) {
          data.append("id", id);
        }
        data.append("fileKey", body.fileKey);
        data.append("fileContent", body.fileContent);
        return {
          url: `/admin/brand-model/image/upload`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "BrandModel" as const, id: args.id },
      ],
    }),
  }),
});

export const { useGetAllQuery, useUploadImageMutation } = brandModelAPI;
