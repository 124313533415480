import axios from "axios";
import { getToken } from "../../helpers";

const localhostList = ["localhost", "127.0.0.1"];

export const API_URL = localhostList.includes(window.location.hostname)
  ? import.meta.env.VITE_REACT_APP_LOCAL_URL
  : import.meta.env.VITE_REACT_APP_API_URL;


export const defaultParams = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
    "Content-Type": "application/json",
  },
});

export const adminInstance = axios.create({
  baseURL: `${API_URL}/admin`,
});

export const globalInstance = axios.create({
  baseURL: API_URL,
});

export const uploadInstance = axios.create({
  baseURL: `${API_URL}/admin`,
  headers: {
    "Content-Type": `multipart/form-data`,
  },
});

export default axios;
