import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getIndustryProducts = createAsyncThunk(
  'catalog/industry-product/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getIndustryProducts({query});
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deleteIndustryProduct = createAsyncThunk(
  'catalog/industry-product/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deleteIndustryProduct(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  products: null,
  metadata: null,
  loading: false,
}

export const industryProductSlice = createSlice({
  name: 'catalog/industry-product',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.products = state.products.filter(item => action.payload.id !== item.id)
    }
  },
  extraReducers: builder => {
    builder.addCase(getIndustryProducts.fulfilled, (state, action) => {
        const { products, metadata } = action.payload;
        state.products = products;
        state.metadata = metadata;
        state.loading = false;
    }),
    builder.addCase(getIndustryProducts.rejected, (state, action) => {
      state.products = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getIndustryProducts.pending, (state, action) => {
      state.products = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deleteIndustryProduct.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deleteIndustryProduct.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deleteIndustryProduct.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = industryProductSlice.actions;
export default industryProductSlice.reducer
