import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import initAuth0 from "@/shared/components/account/auth/withAuth0";
import TimepickerStyles from "@/shared/components/form/date-pickers/timepickerStyles";
import Loading from "@/shared/components/Loading";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import GlobalStyles from "./globalStyles";
import RechartStyles from "./rechartStyles";
import NotificationStyles from "./notificationStyles";
import CalendarStyles from "./calendarStyles";


const ThemeComponent = ({ children }) => {
  const { mode, direction, shadow, border } = useSelector((state) => ({
    mode: state.theme.className,
    direction: state.rtl.direction,
    shadow: state.shadow.className,
    border: state.border.className,
  }));

  const theme = createTheme({
    palette: {
      type: mode,
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider
        theme={{
          mode,
          direction,
          shadow,
          border,
        }}
      >
        <GlobalStyles />
        <NotificationStyles />
        <RechartStyles />
        <TimepickerStyles />
        <CalendarStyles />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ConnectedThemeComponent = ThemeComponent;

const App = () => {
  useEffect(() => {
    window.addEventListener("load", initAuth0);
  }, []);

  return (
    <BrowserRouter basename="/">
      <ConnectedThemeComponent>
        <ScrollToTop>
          <Router />
        </ScrollToTop>
      </ConnectedThemeComponent>
    </BrowserRouter>
  );
};

export default App;
