import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { IUser } from "@/types/DTO/user";

interface UserState {
  users: IUser[] | null;
  metadata: any | null;
  loading: boolean;
}

export const getUsers = createAsyncThunk(
  "catalog/user/get",
  async (
    { query = "page=1", payload = {} }: { query?: string; payload?: any },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await api.getUsers({ query });
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "catalog/user/delete",
  async (
    { id }: { id: number },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.deleteUser(id);
      const data = await response.data;
      console.log({ data });

      dispatch(updateStore({ id }));
      return fulfillWithValue(data);
    } catch (err: any) {
      console.log({ err });

      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const initialState: UserState = {
  users: null,
  metadata: null,
  loading: false,
};

export const userSlice = createSlice({
  name: "catalog/user",
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ id: number }>) => {
      state.users =
        state.users?.filter((item) => action.payload.id !== item.id) || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const { users, metadata } = action.payload;
      state.users = users;
      state.metadata = metadata;
      state.loading = false;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.users = null;
      state.metadata = null;
      state.loading = false;
    });
    builder.addCase(getUsers.pending, (state, action) => {
      state.users = null;
      state.metadata = null;
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const { updateStore } = userSlice.actions;
export default userSlice.reducer;
