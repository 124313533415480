import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { BrandModelState } from "@/types/DTO/brand-models";

const initialState: BrandModelState = {
  models: null,
  metadata: null,
  loading: false,
};

export const getBrandModels = createAsyncThunk(
  "catalog/brand-model/get",
  async ({ query = "page=1" }: { query?: string; }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.getBrandModels({ query });
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err: any) {
      console.log({ err });
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const deleteBrandModel = createAsyncThunk(
  "catalog/brand-model/delete",
  async (
    { id }: { id: number },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.deleteBrandModel(id);
      const data: any = await response.data; 

      dispatch(updateStore({ id }));
      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const brandModelSlice = createSlice({
  name: "catalog/brand-model",
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ id: number }>) => {
      if (state.models) {
        state.models = state.models.filter(
          (item) => action.payload.id !== item.id
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandModels.fulfilled, (state, action) => {
        const { brandModels, metadata } = action.payload;
        state.metadata = metadata;
        state.models = brandModels;
        state.loading = false;
      })
      .addCase(getBrandModels.rejected, (state, action) => {
        state.models = null;
        state.metadata = null;
        state.loading = false;
      })
      .addCase(getBrandModels.pending, (state, action) => {
        state.models = null;
        state.metadata = null;
        state.loading = true;
      })
      .addCase(deleteBrandModel.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBrandModel.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteBrandModel.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { updateStore } = brandModelSlice.actions;
export default brandModelSlice.reducer;
