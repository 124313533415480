import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../utils/api'

export const getIssues  = createAsyncThunk(
  'catalog/issue/get',
  async ({query="page=1", payload={}}={}, {fulfillWithValue, rejectWithValue}) => {
    try {
      const response = await api.getIssues({query});
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err) {
      console.log({ err })
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

export const deleteIssue = createAsyncThunk(
  'catalog/issue/delete',
  async ({id}, {fulfillWithValue, rejectWithValue, dispatch}) => {
    try {
      const response = await api.deleteIssue(id);
      const data = await response.data;
      
      dispatch(updateStore({id}));
      return fulfillWithValue(data);
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response);
    }
  }
)

const initialState = {
  issues: null,
  metadata: null,
  loading: false,
}

export const issueSlice = createSlice({
  name: 'catalog/issue',
  initialState,
  reducers: {
    updateStore: (state, action) => {
      state.issues = state.issues.filter(item => action.payload.id !== item.id)
    },
  },
  extraReducers: builder => {
    builder.addCase(getIssues.fulfilled, (state, action) => {
        const { issues, metadata } = action.payload;
        state.issues = issues;
        state.metadata = metadata;
        state.loading = false;
    }),
    builder.addCase(getIssues.rejected, (state, action) => {
      state.issues = null;
      state.metadata = null;
      state.loading = false;
    }),
    builder.addCase(getIssues.pending, (state, action) => {
      state.issues = null;
      state.metadata = null;
      state.loading = true;
    }),
    builder.addCase(deleteIssue.fulfilled, (state, action) => {
        state.loading = false;
    }),
    builder.addCase(deleteIssue.rejected, (state, action) => {
      state.loading = false;
    }),
    builder.addCase(deleteIssue.pending, (state, action) => {
      state.loading = true;
    })
  }
})

export const {updateStore} = issueSlice.actions;
export default issueSlice.reducer
