import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { IIndustryProduct } from '@/types/DTO/industryProduct';
import { IMetadata } from '@/types/DTO/metadata';


interface IndustryState {
  industries: IIndustryProduct[] | null;
  metadata: IMetadata | null; 
  loading: boolean;
}

export const getIndustries = createAsyncThunk(
  'catalog/industry/get',
  async ({ query = 'page=1' }: { query?: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.getIndustries({ query });
      const data = await response.data;
      
      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const deleteIndustry = createAsyncThunk(
  'catalog/industry/delete',
  async ({ id }: { id: number }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await api.deleteIndustry(id);
      const data = await response.data;
      dispatch(updateStore({ id }));
      return fulfillWithValue(data);
    } catch (err:  any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const initialState: IndustryState = {
  industries: null,
  metadata: null,
  loading: false,
};

export const industrySlice = createSlice({
  name: 'catalog/industry',
  initialState,
  reducers: {
    updateStore: (state, action: PayloadAction<{ id: number }>) => {
      state.industries = state.industries?.filter(item => action.payload.id !== item.id) || null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getIndustries.fulfilled, (state, action) => {
      const { industries, metadata } = action.payload;
      state.industries = industries;
      state.metadata = metadata;
      state.loading = false;
    });
    builder.addCase(getIndustries.rejected, (state, action) => {
      state.industries = null;
      state.metadata = null;
      state.loading = false;
    });
    builder.addCase(getIndustries.pending, (state, action) => {
      state.industries = null;
      state.metadata = null;
      state.loading = true;
    });
    builder.addCase(deleteIndustry.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteIndustry.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteIndustry.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const { updateStore } = industrySlice.actions;
export default industrySlice.reducer;
