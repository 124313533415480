import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";

interface LocationState {
  locations: any[] | null;
  metadata: any | null;
  loading: boolean;
}

interface GetLocationsArgs {
  query?: string;
  payload?: any;
}

export const getLocations = createAsyncThunk(
  "/location",
  async (
    { query = "page=1", payload = {} }: GetLocationsArgs = {},
    { fulfillWithValue, rejectWithValue }
  ) => {
    console.log("Called APi")

    try {
      const response = await api.getLocations({ query });
      const data = await response.data;

      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

interface DeleteLocationArgs {
  id: string;
}

export const deleteLocation = createAsyncThunk(
  "catalog/location/delete",
  async (
    { id }: DeleteLocationArgs,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.deleteLocation(id);
      const data = await response.data;

      dispatch(updateLocation({ id }));
      return fulfillWithValue(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

const initialState: LocationState = {
  locations: null,
  metadata: null,
  loading: false,
};

export const locationSlice = createSlice({
  name: "catalog/location",
  initialState,
  reducers: {
    updateLocation: (state, action: PayloadAction<{ id: string }>) => {
      state.locations =
        state.locations?.filter((item) => action.payload.id !== item.id) ||
        null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.fulfilled, (state, action) => {
      const { locations, metadata } = action.payload;
      state.locations = locations;
      state.metadata = metadata;
      state.loading = false;
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.locations = null;
      state.metadata = null;
      state.loading = false;
    });
    builder.addCase(getLocations.pending, (state, action) => {
      state.locations = null;
      state.metadata = null;
      state.loading = true;
    });
    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteLocation.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteLocation.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export const { updateLocation } = locationSlice.actions;
export default locationSlice.reducer;
